import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import States from '../data/states.json';
import InputText from '../components/form/InputText';
import Select from '../components/form/Select';
import Button from '../components/form/Button';
import Radio from '../components/form/Radio';
import FormStatus from '../components/form/FormStatus';
import { useTranslation } from "react-i18next";

type City = {
	value: string;
	name: string;
}

type State = {
	value: string;
	name: string;
}

interface FormValues {
	name: string;
	email: string;
	mobile_phone: string;
	city_id: string;
	state_id: string;
	gender_id: number;
	site_id: string | number | undefined;
	api_token: string | number | undefined;
}

interface Cities {
	[key: string]: City[];
}

const Cities: Cities = require('../data/cities.json');

const RegisterForm: React.FC = () => {
	const [selectedState, setSelectedState] = useState('');
	const [selectedCity, setSelectedCity] = useState('');
	const [formStatus, setFormStatus] = useState('idle');
	const { t } = useTranslation();

	const getCitiesForState = (stateValue: string): City[] => {
		return Cities[stateValue] || [];
	};

	const initialValues: FormValues = {
		name: '',
		email: '',
		mobile_phone: '',
		city_id: '',
		state_id: '',
		gender_id: 1,
		site_id: process.env.REACT_APP_SITE_ID,
		api_token: process.env.REACT_APP_API_TOKEN,
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(t('validation.name_required')).min(6, t('validation.name_minlength')),
		email: Yup.string().required(t('validation.email_required')).email(t('validation.email_invalid')),
		mobile_phone: Yup.string().required(t('validation.phone_required')).matches(/^\(\d{2}\) \d{5}-\d{3,4}$/, t('validation.phone_invalid')),
		state_id: Yup.string().required(t('validation.state_required')),
		city_id: Yup.string().required(t('validation.city_required')),
	});

	const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
		try {
			setFormStatus('is_submiting');
			
			const registerUrl = process.env.REACT_APP_API_URL + '/register';
			const response = await axios.get(registerUrl, { params: values });

			if (response.status === 200) {
				if (response.data.status) {
					setFormStatus(response.data.status);
				}
			} else {
				setFormStatus('error');
			}
		} catch (error) {
			setFormStatus('error');
			console.error(error);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
			{({ isSubmitting, values, errors, touched, setFieldValue }) => (
				<Form className="relative">
					{formStatus !== 'idle' && (
						<FormStatus
							title={t(`form.${formStatus}`)}
							description={t(`form.${formStatus}_description`)}
						/>
					)}
					<div>
						<div className="flex items-center flex-col md:flex-row justify-start gap-2 md:gap-4 mb-4">
							<Radio name="gender_id" value={1} label={t('genders.female')} checked={values.gender_id === 1} onChange={() => setFieldValue('gender_id', 1)} />
							<Radio name="gender_id" value={2} label={t('genders.male')} checked={values.gender_id === 2} onChange={() => setFieldValue('gender_id', 2)} />
							<Radio name="gender_id" value={3} label={t('genders.others')} checked={values.gender_id === 3} onChange={() => setFieldValue('gender_id', 3)} />
						</div>
					</div>

					<div>
						<InputText name="name" label={t('form.name')} />
					</div>

					<div>
						<InputText name="email" label={t('form.email')} />
					</div>

					<div>
						<InputText name="mobile_phone" label={t('form.phone')} mask="(99) 99999-999?" formatChars={{ 9: '[0-9]', '?': '[-\0-9]' }} />
					</div>

					<div>
						<Select
							label={t('form.state')}
							options={States.map((state: State) => ({ value: state.value, label: state.name }))}
							value={selectedState}
							name="state_id"
							onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
								setSelectedState(event.target.value);
								setFieldValue('state_id', event.target.value);
							}}
						/>
					</div>

					<div>
						{selectedState && (
							<Select
								label={t('form.city')}
								options={getCitiesForState(selectedState).map((city: City) => ({ value: city.value, label: city.name }))}
								value={selectedCity}
								name="city_id"
								blankOption={true}
								onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
									setSelectedCity(event.target.value);
									setFieldValue('city_id', event.target.value);
								}}
								disabled={!selectedState}
							/>
						)}
					</div>

					<div>
						<Button>{t('form.submit')}</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default RegisterForm;