import React, {useRef} from "react";
import { useTranslation } from "react-i18next";
import { Link, animateScroll as scroll } from "react-scroll";
import NavLink from "../components/NavLink";

import ToolbarLogo from '../assets/images/logo-toolbar.webp';
import BrFlag from '../assets/images/flags/flag-brazil.svg';
import UsFlag from '../assets/images/flags/flag-usa.svg';

import Footer from "../partials/Footer";

interface LandingProps {
	children: React.ReactNode;
}

const Landing = ({children}: LandingProps) => {

	const { t, i18n } = useTranslation();

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language);
	};

	return (
		<>
			<main className="w-full">{children}</main>
			<Footer />
		</>
	);
}

export default Landing;