import React from 'react';
import { useTranslation } from 'react-i18next';

import FooterLogo from '../assets/images/logo.svg';

const Footer = () => {

	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();
	
	return (
		<footer className="relative w-full bg-black" id="footer">
			<div className="w-full flex flex-col items-center gap-4 justify-center p-8">
				<img src={FooterLogo} alt="Mega Model Sul" className="w-42 mx-auto" />
				<div className="flex flex-col lg:flex-row items-center gap-4 text-white text-md font-poppins">
					<a href="https://megamodelkids.com.br/politica-de-privacidade" target="_blank" rel="noreferrer" className="hover:underline">{ t('privacy-policy') }</a>
					<a href="https://megamodelkids.com.br/termos-de-uso" target="_blank" rel="noreferrer" className="hover:underline">{ t('therms-of-use') }</a>
				</div>
			</div>
			<small className="block w-full px-4 py-2 border-t-2 border-gray-900 text-white font-poppins text-center">
				{ process.env.REACT_APP_NAME } &copy; { currentYear } - { t('all-rights-reserved') }
			</small>
		</footer>
	)
}

export default Footer;