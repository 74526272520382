import React from 'react';
import Landing from './layouts/Landing';
import Home from './pages/Home';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const App: React.FC = () => {
	return (
		<I18nextProvider i18n={i18n}>
			<Landing>
				<Home />
			</Landing>
		</I18nextProvider>
	);
}

export default App;
