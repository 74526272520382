import React from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from "react-scroll";
import RegisterForm from '../partials/RegisterForm';
import Logo from '../assets/images/logo.svg';

export default function Home () {

	const { t } = useTranslation();
 	
	return (
		<main id="main">
			<div className="relative w-full lg:grid lg:grid-cols-2 bg-white">
				<div className="h-full text-white bg-slate-700 flex items-left justify-center flex-col gap-4 p-4 lg:p-8 text-left">
					<figure className="relative h-[90px] lg:h-[120px] -ml-16 lg:-ml-20">
						<img src={Logo} alt={process.env.REACT_APP_NAME} className="h-full object-cover object-right" />
					</figure>
					<h1 className="text-xl lg:text-3xl font-title font-bold text-orange-300 uppercase lg:max-w-[360px]">Faça Parte da {process.env.REACT_APP_NAME}</h1>
					<p className="text-lg">Bem-vindo à {process.env.REACT_APP_NAME}, onde a diversidade é nossa força. Celebramos a beleza em todas as formas, representando uma variedade de corpos reais. Descubra a autenticidade na moda e na modelagem.</p>
				</div>
				<Element name="subscribe" className="max-lg:py-8 w-full flex flex-col p-2 md:p-4 lg:p-8 items-center justify-center bg-gray-300" id="subscribe">
					<h2 className="mb-4 px-4 lg:px-16 text-3xl text-center w-full font-poppins font-bold text-slate-900">A Maior Agência de Modelos da América Latina</h2>
					<div className="bg-white rounded p-8 w-full md:shadow-lg">
						<RegisterForm />
					</div>
				</Element>
			</div>
		</main>
	);
}
